import React from 'react'
import AnimatedCursorComponent from 'react-animated-cursor'

const AnimatedCursor = () => (
  <AnimatedCursorComponent
    className="cursor"
    innerSize={8}
    outerSize={30}
    color="230, 230, 230"
    outerAlpha={0.5}
    innerScale={0.7}
    outerScale={1.5}
    zIndex="1000000"
    clickables={[
      'a',
      'i',
      'input[type="text"]',
      'input[type="email"]',
      'input[type="number"]',
      'input[type="submit"]',
      'input[type="image"]',
      'label[for]',
      'select',
      'textarea',
      'button',
      '.link',
      '.sub-menu',
      '.has-child',
      '.menu-toggle-wrap',
      '.search-cross-btn',
      '.SRLControls',
    ]}
  />
)

export default AnimatedCursor
