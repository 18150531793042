import React from 'react'

function Preloader() {
  return (
    <>
      <div className="preloader">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  )
}

export default Preloader
