import React from 'react'

const SocialIcon = ({ href, iconClassName }) => (
  <li>
    <a rel="noopener noreferrer" href={href} target="_blank">
      <i className={`bx ${iconClassName}`} />
    </a>
  </li>
)

export default function SocialIcons({ className }) {
  return (
    <ul className={className}>
      <SocialIcon
        href="https://www.facebook.com/profile.php?id=100057482077856"
        iconClassName="bxl-facebook"
      />
      <SocialIcon
        href="https://www.linkedin.com/company/m8b/"
        iconClassName="bxl-linkedin"
      />
      <SocialIcon
        href="https://twitter.com/devm8b"
        iconClassName="bxl-twitter"
      />
    </ul>
  )
}
