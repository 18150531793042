import React from 'react'
import { Link } from 'react-router-dom'
import { SwiperSlide } from 'swiper/react'

const BannerElement = ({
  image,
  title,
  paragraph,
  annotation,
  callToAction,
}) => {
  return (
    <SwiperSlide
      tag="article"
      className="swiper-slide position-relative row align-items-center container-fluid"
    >
      <div className="col-lg-5 col-md-7 d-md-block d-none py-2">
        <div className="banner-img">
          <img src={image} className="banner-img-left" alt="" />
          <img
            className="top-right-dot"
            src={process.env.PUBLIC_URL + '/images/banner/top-right-dot.svg'}
            alt=""
          />
          <img
            className="bottom-left-dot"
            src={process.env.PUBLIC_URL + '/images/banner/bottom-left-dot.svg'}
            alt=""
          />
        </div>
      </div>
      <div className="col-lg-7 col-md-5 col-12">
        <div className="banner-content mobile-bg1">
          <h2 className="banner-text-bg">
            BLOCKCHAIN <br />
            TECHNOLOGY
          </h2>
          <h5>{annotation}</h5>
          <h1>{title}</h1>
          <p>{paragraph}</p>
          <Link
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
            to={`${process.env.PUBLIC_URL}/contact-us`}
            className="eg-btn banner-btn hover-btn"
          >
            {callToAction}
            <img
              src={process.env.PUBLIC_URL + '/images/icon/btn-arrow.svg'}
              alt="images"
            />
          </Link>
        </div>
      </div>
    </SwiperSlide>
  )
}

BannerElement.displayName = 'SwiperSlide'

export default BannerElement
