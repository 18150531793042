import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function MenuLink({ to, title }) {
  const { pathname } = useLocation()

  return (
    <li className="menu-item">
      <Link
        to={to}
        className={`menu-link ${pathname === to ? 'menu-link--active' : ''}`}
      >
        {title}
      </Link>
    </li>
  )
}
