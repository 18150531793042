import React from 'react'
import SmallLink from './SmallLink'

/**
 * This function is a React component that takes in a bunch of props and returns a div with an image, a div with a title,
 * and a list of links
 * @param {string} image - A src of the image
 * @param {string} name - Name of team member
 * @param {string} title - Title of team member
 * @param {string} fbLink - Link to Facebook
 * @param {string} twLink - Link to Twitter
 * @param {string} inLink - Link to LinkedIn
 * @param {string} prLink - Link to Pinterest
 * @returns A team member with a picture, name, title, and links to social media.
 */
function TeamItem({ image, name, title, fbLink, twLink, inLink, prLink }) {
  return (
    <>
      <div class="team-item">
        <img src={image} alt={name} />
        <div class="team-overlay">
          <div class="overlay-content hover-btn">
            <h4>{name}</h4>
            <p>{title}</p>
            <ul>
              {fbLink && <SmallLink href={fbLink} title="FB" />}
              {twLink && <SmallLink href={twLink} title="TW" />}
              {inLink && <SmallLink href={inLink} title="IN" />}
              {prLink && <SmallLink href={prLink} title="PR" />}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamItem
