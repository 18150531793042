import React from 'react'
import { Link } from 'react-router-dom'
import { scrollTop } from '../../../helpers/scrollTop'
import TeamItem from '../../common/TeamItem'

function TeamArea() {
  return (
    <>
      <div className="team-section pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Our Team</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.7s"
            >
              <TeamItem
                image={`${process.env.PUBLIC_URL}/images/bg/t1.jpeg`}
                name="Błażej"
                title=""
                fbLink=""
                twLink=""
                inLink=""
                prLink=""
              />
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.7s"
            >
              <TeamItem
                image={`${process.env.PUBLIC_URL}/images/bg/t4.jpeg`}
                name="Bartek"
                title=""
                fbLink=""
                twLink=""
                inLink=""
                prLink=""
              />
            </div>
            <div
              className="col-lg-4 col-md-8 col-sm-8 order-lg-1 order-0 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <div className="section-card team-card">
                <h2>Meet our crew</h2>
                <p>
                  Our group comprises of blockchain specialists and ethusiasts
                  perceived for their endeavors!{' '}
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/team`}
                  onClick={() => scrollTop()}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    Read about us
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="images"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamArea
