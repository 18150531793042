import React from 'react'
import { Link } from 'react-router-dom'

function ContactWrap() {
  const scrolltop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const copy = (value) => navigator.clipboard.writeText(value)

  return (
    <>
      <div className="contact-section pt-120 pb-120">
        <div className="container">
          <div className="row pb-120 g-4 d-flex justify-content-center">
            <ContactElement
              icon="bi bi-geo-alt"
              href="https://maps.app.goo.gl/69QJmvHPc6tq6Xbf7"
            >
              <h3>Location</h3>
              <h4>aleja Bolesława Krzywoustego 4/1</h4>
              <h5>40-870 Katowice, Poland</h5>
            </ContactElement>
            <ContactElement icon="bx bx-phone-call" href="tel:+48881793777">
              <h3>Phone</h3>
              <h4>+48 881 793 777</h4>
            </ContactElement>
            <ContactElement icon="bx bx-envelope" href="mailto:patryk@m8b.pl">
              <h3>Email</h3>
              <h4>patryk@m8b.pl</h4>
            </ContactElement>
            <ContactElement
              icon="bx bxl-discord"
              onClick={() => copy('Patryk Fox#5722')}
            >
              <h3>Discord</h3>
              <h4>Patryk Fox#5722</h4>
            </ContactElement>
            <ContactElement
              icon="bx bxl-linkedin"
              href="https://www.linkedin.com/company/m8b"
            >
              <h3>LinkedIn</h3>
            </ContactElement>
            <ContactElement
              icon="bx bxl-twitter"
              href="https://twitter.com/devm8b"
            >
              <h3>Twitter</h3>
              <h4>@DevM8B</h4>
            </ContactElement>
          </div>
          <div className="row d-flex justify-content-lg-end justify-content-center">
            <div className="col-md-12 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Let&#x2019;s talk.</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className="section-card contact-section-card wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Experts Advice</h2>
                <p>
                  Contact us to get the best advice on how to run a blockchain
                  based application.
                </p>
                <Link
                  onClick={scrolltop}
                  to={`${process.env.PUBLIC_URL}/team`}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    Our Specialists
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="icons"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactWrap

function ContactElement({ icon, href, onClick, children }) {
  return (
    <a
      className="col-lg-4 col-md-6 col-sm-8"
      href={href}
      onClick={onClick}
      target={href ? '_blank' : ''}
      rel="noreferrer"
    >
      <div className="eg-card contact-signle hover-btn">
        <div className="icon">
          <i className={icon} />
        </div>
        <div className="text">{children}</div>
      </div>
    </a>
  )
}
