import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import ContactWrap from './ContactWrap'

function ContaceUsPage() {
  return (
    <>
      <Breadcrumb name="Contact Us" />
      <ContactWrap />
    </>
  )
}

export default ContaceUsPage
