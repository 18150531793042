import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Layout from './components/App'
import MainLayout from './components/layout/MainLayout'
import SimpleReactLightbox from 'simple-react-lightbox'
import './index.scss'
import ContaceUsPage from './components/page/contactUs/ContaceUsPage'
import TeamPage from './components/page/team/TeamPage'
import ErrorPage from './components/page/error/ErrorPage'
import ProjectPage from './components/page/projects/ProjectPage'
import ServicePage from './components/page/service/ServicePage'
import Preloader from './components/layout/Preloader'

/*
 * Version :Tourx-pro 0.1
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide
// console.log = console.warn = console.error = () => {}

function Root() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])

  if (loading) return <Preloader />

  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          {/*main layout*/}
          <Route exact path="/" component={MainLayout} />
          {/* all inner page load layout component */}
          <Layout>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-us`}
              component={ContaceUsPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={TeamPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/error`}
              component={ErrorPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/project`}
              component={ProjectPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/projects`}
              component={ProjectPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={ServicePage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/#`}
              component={ServicePage}
            />
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
)
