import React from 'react'
import AnimatedCursor from './common/AnimatedCursor'
import Header from './common/header/Header'
import Footer from './common/Footer'

function Layout({ children }) {
  return (
    <>
      <AnimatedCursor />
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout
