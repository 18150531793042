import React from 'react'
import Banner from './Banner'
import Sponsor from '../../common/Sponsor'
import JoinUs from '../../common/cta/JoinUs'
import HomeServices from './HomeServices'
import TeamArea from './TeamArea'
import AchivmentArea from '../../common/AchivementArea'
import AboutArea from './AboutArea'
import TestimonialOne from './TestimonialOne'
import HomeoneLetestWork from './HomeoneLetestWork'
import Twitter from '../../common/cta/Twitter'

function HomePage() {
  return (
    <>
      <Banner />
      <HomeServices />
      <Twitter padding="pt-120" />
      <HomeoneLetestWork />
      <AboutArea />
      <AchivmentArea />
      <TeamArea />
      <TestimonialOne />
      <JoinUs padding="pt-120" />
      <Sponsor />
    </>
  )
}

export default HomePage
