import React from 'react'
import { Link } from 'react-router-dom'
import { scrollTop } from '../../helpers/scrollTop'

const Copyright = () => (
  <h6 className="copyright">
    Copyright &copy; {new Date().getFullYear()}{' '}
    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
      m8b
    </Link>
  </h6>
)

export default Copyright
