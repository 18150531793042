import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import WOW from 'wowjs'
import { scrollTop } from '../../../helpers/scrollTop'
import Copyright from '../Copyright'
import SmallLink from '../SmallLink'
import SocialIcons from '../SocialIcons'
import MenuLink from './MenuLink'

function Header() {
  // Sticky Navbar
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init()
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })
  const isSticky = (e) => {
    const header = document.querySelector('.header-section')
    const menuscrollTop = window.scrollY
    menuscrollTop >= 20
      ? header.classList.add('sticky')
      : header.classList.remove('sticky')
  }

  /*----for single sidebar event use one state-------*/
  const [sidebar, setSidebar] = useState(false)
  const [sidebarLeft, setSidebarLeft] = useState(false)
  const [searchBar, setSearchBar] = useState(false)
  const handleSearchbar = () => {
    if (searchBar === false || searchBar === 0) {
      setSearchBar(1)
    } else {
      setSearchBar(false)
    }
  }
  const showSidebar = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1)
    } else {
      setSidebar(false)
    }
  }
  const showSidebarLeft = () => {
    if (sidebarLeft === false || sidebarLeft === 0) {
      setSidebarLeft(1)
    } else {
      setSidebarLeft(false)
    }
  }

  return (
    <>
      <aside
        className={
          sidebarLeft === 1
            ? 'menu-toggle-btn-full-shape show-sidebar'
            : 'menu-toggle-btn-full-shape'
        }
      >
        <div className="menu-toggle-wrap bg-white">
          <div className="sidebar-top-area">
            <div onClick={showSidebarLeft} className="cross-icon">
              <i className="bx bx-x" />
            </div>
            <div className="sidebar-logo">
              <Link onClick={scrollTop} to={'/'}>
                <img
                  src={process.env.PUBLIC_URL + '/images/icon/logo.png'}
                  alt="images"
                />
              </Link>
            </div>
            <p>
              Meet m8b. An agile team of developers specializing in blockchain
              technology, with ambitious goal of accelerating crypto mass
              adoption to the world. Our One line of code at a time.
            </p>
          </div>
          <div className="sidebar-bottom">
            <h3 className="sidebar-title">Follow Us On Our Socials</h3>
            <SocialIcons className="sidebar-social-list" />
            <Copyright />
          </div>
        </div>
      </aside>
      <div
        className={searchBar === 1 ? 'mobile-search slide' : 'mobile-search'}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>What are you looking for?</label>
              <input
                type="text"
                placeholder="Search Projects, Services, Team"
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div onClick={handleSearchbar} className="search-cross-btn">
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="header-section">
        <div className="container-fluid">
          <div className="header-area">
            <div className="header-logo">
              <Link onClick={scrollTop} to={'/'}>
                <img
                  src={process.env.PUBLIC_URL + '/images/icon/logo.png'}
                  alt="images"
                />
              </Link>
            </div>
            <div className="mobile-menu-btn text-white d-lg-none d-block">
              <i onClick={showSidebar} className="bi bi-list" />
            </div>
            <nav
              className={sidebar === 1 ? 'main-menu show-menu' : 'main-menu'}
            >
              <div className="mobile-logo-area d-lg-none d-block">
                <div className="mobile-logo-wrap d-flex justify-content-between align-items-center">
                  <img
                    src={process.env.PUBLIC_URL + '/images/icon/logo.png'}
                    alt="images"
                  />
                  <div className="menu-close-btn" onClick={showSidebar}>
                    <i className="bi bi-x-lg" />
                  </div>
                </div>
              </div>
              <ul className="menu-list">
                <MenuLink to="/" title="Home" />
                <MenuLink to="/team" title="Team" />
                <MenuLink to="/projects" title="Projects" />
                <MenuLink to="/service" title="Services" />
                <MenuLink to="/contact-us" title="Contact" />
              </ul>
              {/* mobile-search-area */}
              <div className="mobile-menu-bottom d-lg-none d-block">
                <Link to={'#'} className="mobile-menu-button eg-btn">
                  Start Project
                </Link>
                <ul className="header-social">
                  <SmallLink href="https://twitter.com/devm8b" title="TW" />
                  <SmallLink
                    href="https://www.facebook.com/profile.php?id=100057482077856"
                    title="FB"
                  />
                  <SmallLink
                    href="https://www.linkedin.com/company/m8b"
                    title="IN"
                  />
                </ul>
              </div>
            </nav>
            <div className="nav-right-area">
              <Link
                to={`${process.env.PUBLIC_URL}/contact-us`}
                onClick={scrollTop}
                className="eg-btn header-button hover-btn"
              >
                Contact us <i className="bx bx-envelope" />
              </Link>
              <ul className="header-social">
                <SmallLink href="https://twitter.com/devm8b" title="TW" />
                <SmallLink
                  href="https://www.facebook.com/profile.php?id=100057482077856"
                  title="FB"
                />
                <SmallLink
                  href="https://www.linkedin.com/company/m8b/"
                  title="IN"
                />
              </ul>
              <div
                onClick={showSidebarLeft}
                className="sidebar-header menu-sidebar-btn"
              >
                <i className="bx bx-menu-alt-left" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
