import React from 'react'
import SectionTitle from '../../common/SectionTitle'
import TeamItem from '../../common/TeamItem'

function TeamWrap() {
  return (
    <>
      <div className="team-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <SectionTitle title="Our Team" />
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-4 col-md-6 col-sm-8 order-1">
              <TeamItem
                image={process.env.PUBLIC_URL + '/images/bg/t1.jpeg'}
                name="Błażej"
                title="Founder, CEO"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8 order-1">
              <TeamItem
                image={process.env.PUBLIC_URL + '/images/bg/t2.jpeg'}
                name="Patryk"
                title="Co-Founder, COO"
              />
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8 order-lg-1 order-0 d-flex align-items-start">
              <div className="section-card team-card">
                <h2>Meet our crew</h2>
                <p className="mb-0">
                  Our team consists of curious problem solvers and developers,
                  we're focused on teamwork and transparency with our customers.
                  We aim to deliver tested, high quality product to the market
                  as soon as possible.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <TeamItem
                image={process.env.PUBLIC_URL + '/images/bg/t3.jpeg'}
                name="Mateusz"
                title="Co-Founder, CTO"
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <TeamItem
                image={process.env.PUBLIC_URL + '/images/bg/t4.jpeg'}
                name="Bartek"
                title="Blockchain Consultant"
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <TeamItem
                image={process.env.PUBLIC_URL + '/images/bg/t5.jpeg'}
                name="Stanisław"
                title="Backend"
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <TeamItem
                image={process.env.PUBLIC_URL + '/images/bg/t6.jpeg'}
                name="Szymon"
                title="Frontend"
                inLink="https://www.linkedin.com/in/rekeye/"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamWrap
