export function ProjectWrapper({ children, large = false }) {
  return (
    <div
      className={`${
        large ? 'col-lg-7 col-md-12' : 'col-lg-4 col-md-6'
      } col-sm-8 order-1 wow animate fadeInDown flex`}
      style={large ? {} : { maxHeight: '200px' }}
      data-wow-duration="1.5s"
      data-wow-delay="0.5s"
    >
      {children}
    </div>
  )
}
