import React, { useState } from 'react'

export const ScrollButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 800) {
      setVisible(true)
    } else if (scrolled <= 100) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <i
      className="bx bxs-up-arrow-circle"
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
    ></i>
  )
}
