import React from 'react'
import { Link } from 'react-router-dom'

function TestimonialOne() {
  return (
    <>
      <div className="testimonial-section pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title pt-0 wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Testimonial</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-8 order-lg-1 order-2">
              <div
                className="eg-card-wrap wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <div className="eg-card testimonial-item d-flex justify-content-sm-start justify-content-center align-items-center flex-sm-nowrap flex-wrap hover-btn">
                  <span />
                  <div className="testimonial-content order-sm-1 order-2">
                    <h6>
                      Blaise and their team are hard and dedicated workers, that
                      do not shy to run the last mile. They have good knowledge
                      about the Ethereum code base and helped us build the Aura
                      Consensus into Geth.
                    </h6>
                    <div className="testimonial-footer d-flex justify-content-between align-items-end">
                      <ul className="social-media d-flex"></ul>
                      <div className="author">
                        <Link to={'#'}>
                          <h5>Fabian Vogelsteller</h5>
                        </Link>
                        <p> Founder and Chief Architect @ LUKSO</p>
                      </div>
                    </div>
                    <img
                      src={process.env.PUBLIC_URL + '/images/icon/quote.svg'}
                      className="quote-icon"
                      alt="images"
                    />
                  </div>
                  <div className="testimonial-img order-sm-2 order-1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/Fabian_Vogelsteller.png'
                      }
                      alt="images"
                    />
                  </div>
                </div>
              </div>
              <div
                className="eg-card-wrap wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <div className="eg-card testimonial-item d-flex justify-content-sm-start justify-content-center align-items-center flex-sm-nowrap flex-wrap hover-btn">
                  <span />
                  <div className="testimonial-content order-sm-1 order-2">
                    <h6>
                      We have cooperated for years. Guys are strong specialists
                      in Ethereum blockchain. They are open for business and
                      development, they can solve all development issues and
                      able to deliver complex solutions for me and my business
                      partners.
                    </h6>
                    <div className="testimonial-footer d-flex justify-content-between align-items-end">
                      <ul className="social-media d-flex"></ul>
                      <div className="author">
                        <Link to={'#'}>
                          <h5>Maciej Krasowski</h5>
                        </Link>
                        <p>
                          CEO @ Fizen.com / Strategy, Fintech and Blockchain
                        </p>
                      </div>
                    </div>
                    <img
                      src={process.env.PUBLIC_URL + '/images/icon/quote.svg'}
                      className="quote-icon"
                      alt="images"
                    />
                  </div>
                  <div className="testimonial-img order-sm-2 order-1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/bg/Maciej_Krasowski.png'
                      }
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8 order-lg-2 order-1">
              <div
                className="section-card testimonial-section-card wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>What they say about us</h2>
                <p>
                  We pride ourselves delivering clean and scalable code &amp;
                  that solves blockchain problems in the most efficient way
                  possible.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/service`}
                  onClic={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    Read more testimonials
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="images"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestimonialOne
