import React from 'react'

/**
 * It returns a list item with a link inside of it
 * @param {string} href - A url to redirect to
 * @param {string} title - Title inside of the link
 * @returns A list item with a link inside of it.
 */
export default function SmallLink({ href, title }) {
  return (
    <li>
      <a rel="noopener noreferrer" target="_blank" href={href}>
        {title}
      </a>
    </li>
  )
}
