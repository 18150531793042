import React from 'react'

export default function Twitter(props) {
  return (
    <div
      className={`${'join-us-section wow animate fadeInDown'} ${props.padding}`}
      data-wow-duration="1.5s"
      data-wow-delay="0.5s"
    >
      <div className="container">
        <div className="join-us-area twitter-bg">
          <div className="join-us-content flex-md-nowrap flex-wrap">
            <h2>Follow us on Twitter!</h2>
            <h5>
              Ready to dive into the latest innovations in tech? Don't miss out
              on the excitement &ndash; follow m8b on Twitter now!
            </h5>
          </div>
          <a
            href="https://twitter.com/devm8b"
            target="_blank"
            rel="noreferrer noopener"
            className="eg-btn join-btn hover-btn"
            style={{ cursor: 'none' }}
          >
            Follow us
            <img
              src={process.env.PUBLIC_URL + '/images/icon/arrow-dark.svg'}
              alt="images"
            />
          </a>
        </div>
      </div>
    </div>
  )
}
