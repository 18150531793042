import React from 'react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'

SwiperCore.use([Navigation, Autoplay])
function Sponsor() {
  return (
    <>
      <div className="sponsor-section">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>
    </>
  )
}

export default Sponsor
