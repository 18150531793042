import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeService({ index, title, icon, handleLink }) {
  return (
    <div
      className="col-xl-4 col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
      data-wow-duration="1.5s"
      data-wow-delay="0.7s"
    >
      <div className="service-item hover-btn service-mt-30 d-flex flex-row align-items-center justify-content-between">
        <div className="s-content">
          <div className="number">{index}</div>
          <h4>
            <Link to={`${process.env.PUBLIC_URL}/#`} onClick={handleLink}>
              {title}
            </Link>
          </h4>
        </div>
        <span />
        <div className="s-icon">{icon}</div>
      </div>
    </div>
  )
}
