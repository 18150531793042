import React from 'react'
import { Link } from 'react-router-dom'
import ProjectCard from '../projects/ProjectCard'
import { ProjectWrapper } from '../projects/ProjectWrapper'

function HomeoneLetestWork() {
  const scrolltop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
      <div className="latest-work pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-end justify-content-center">
            <div className="col-md-7 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Latest Work</h2>
              </div>
            </div>
          </div>
          <div className="row g-4 d-flex justify-content-center ">
            <div className="col-lg-5 col-md-8 col-sm-8 order-lg-1 order-0">
              <div
                className="section-card l-work-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Look at our Latest Work.</h2>
                <p>
                  We guide blockchain-changing companies, across platforms &amp;
                  places, through agile design &amp; digital experience. We make
                  waves.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/projects`}
                  onClick={scrolltop}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    All Work
                    <img
                      src={
                        process.env.PUBLIC_URL + '/images/icon/arrow-servc.svg'
                      }
                      alt="images"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <ProjectWrapper large>
              <ProjectCard
                title="Lukso - Blockchain Consensus Development"
                href="https://lukso.network"
                image={process.env.PUBLIC_URL + '/images/projects/lukso.png'}
                alt="lukso"
              />
            </ProjectWrapper>
            <ProjectWrapper>
              <ProjectCard
                title="Sigmaswap - Blockchain Bridge Development"
                href="https://sigmaswap.io"
                image={
                  process.env.PUBLIC_URL + '/images/projects/sigmaswap.png'
                }
                alt="sigmaswap"
              />
            </ProjectWrapper>
            <ProjectWrapper>
              <ProjectCard
                title="AWST - NFT Platform Development"
                href="https://www.awst.io"
                image={process.env.PUBLIC_URL + '/images/projects/awst.png'}
                alt="awst"
              />
            </ProjectWrapper>
            <ProjectWrapper>
              <ProjectCard
                title="Minerva - Crypto Wallet Development"
                href="https://minerva.digital"
                image={process.env.PUBLIC_URL + '/images/projects/minerva.jpg'}
                alt="minerva"
              />
            </ProjectWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeoneLetestWork
