import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

function AboutArea() {
  const brandingProgress = 85
  const digitalMarketProgress = 65
  const UIUXProgress = 30
  return (
    <>
      <div className="about-section">
        <div className="container position-relative">
          <img
            src={process.env.PUBLIC_URL + '/images/icon/dotted.svg'}
            className="dotted-circle"
            alt="images"
          />
          <img
            src={process.env.PUBLIC_URL + '/images/icon/line-frame.svg'}
            className="line-frame"
            alt="images"
          />
          <div className="row g-4 d-flex justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-8 order-md-3 order-4">
              <div
                className="about-card wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <div className="about-content">
                  <h5>Why Choose Us ?</h5>
                  <h3>We're at the heart of Europe. Our reach is Global.</h3>
                  <p>
                    Our long experience at the top of the events business means
                    we have expertise which reaches across a number of sectors,
                    as well as knowledge of specific protocol and local.{' '}
                  </p>
                  <div className="counter-wrapper d-flex justify-content-xl-between justify-content-center flex-wrap gap-2">
                    <div className="counter-item">
                      <CircularProgressbar
                        value={brandingProgress}
                        text={`${brandingProgress}%`}
                        className="progress-bar-circle circle_percent"
                      />

                      <h4>Development</h4>
                    </div>
                    <div className="counter-item">
                      <CircularProgressbar
                        value={digitalMarketProgress}
                        text={`${digitalMarketProgress}%`}
                        className="progress-bar-circle circle_percent"
                      />
                      <h4>Consulting</h4>
                    </div>
                    <div className="counter-item">
                      <CircularProgressbar
                        value={UIUXProgress}
                        text={`${UIUXProgress}%`}
                        className="progress-bar-circle circle_percent"
                      />
                      <h4>Maintenance</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-8 col-sm-8 order-md-4 order-3">
              <div
                className="about-img wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/abt2_m8b.png'}
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutArea
