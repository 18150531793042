import React from 'react'

function ProjectCard({ image, alt, title, href }) {
  return (
    <a
      className="work-item"
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      <img src={image} alt={alt} />
      <div className="work-overlay">
        <div className="work-title hover-btn">
          <i className="bi bi-arrow-90deg-right" />
          <h3>{title}</h3>
        </div>
      </div>
    </a>
  )
}

export default ProjectCard
