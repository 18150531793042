import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import JoinUs from '../../common/cta/JoinUs'
import Sponsor from './Sponsor'
import TeamWrap from './TeamWrap'

function TeamPage() {
  return (
    <>
      <Breadcrumb name="Our Team" />
      <TeamWrap />
      <JoinUs />
      <Sponsor />
    </>
  )
}

export default TeamPage
