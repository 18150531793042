import React from 'react'
import { Link } from 'react-router-dom'
import { ScrollButton } from './ScrollButton'
import Copyright from './Copyright'
import SocialIcons from './SocialIcons'

function Footer() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <main className="footer-top-area">
            <div className="row d-flex justify-content-sm-start justify-content-between gy-5">
              <div className="col-lg-5 col-md-6 text-sm-start">
                <section className="footer-left-address">
                  <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
                    <img
                      src={process.env.PUBLIC_URL + '/images/icon/logo.png'}
                      className="footer-logo"
                      alt="images"
                    />
                  </Link>
                  <ul className="address-list">
                    <li>
                      <a href="mailto:kontakt@m8b.pl">Email: kontakt@m8b.pl</a>
                    </li>
                    <li>
                      <a
                        href="https://maps.app.goo.gl/69QJmvHPc6tq6Xbf7"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Address: aleja Bolesława Krzywoustego 4/1 <br />
                        40-870 Katowice, Poland
                      </a>
                    </li>
                    <li>
                      <a href="tel:+48881793777">Phone: +48 881 793 777</a>
                    </li>
                    <li>NIP: PL6342964129</li>
                    <li>REGON: 38383655200000</li>
                    <li>KRS: 0000794051</li>
                  </ul>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="footer-social-area d-flex justify-content-sm-start justify-content-center align-items-center flex-wrap">
                        <h5>Follow Us On Our Socials</h5>
                        <SocialIcons className="footer-social-list" />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-7 col-md-6">
                <section className="footer-right-area">
                  <div className="row g-4 flex-d justify-content-around">
                    <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Explore On</h5>
                        <ul className="footer-link">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/`}
                              onClick={scrollTop}
                            >
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/team`}
                            >
                              Meet Our Team
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/projects`}
                              onClick={scrollTop}
                            >
                              Our Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              Our Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/contact-us`}
                              onClick={scrollTop}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Our Services</h5>
                        <ul className="footer-link">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              Blockchain Development
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              Smart Contracts
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              Web and mobile DApps, Wallets
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              Blockchain Consulting
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              Blockchain DevOps
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
          <section className="footer-bottom-area">
            <div className="row">
              <div className="col-lg-5 text-lg-start text-center">
                <div className="footer-bottom-left">
                  <Copyright />
                </div>
              </div>
            </div>
          </section>
        </div>
      </footer>

      <div className="scroll-btn show">
        <ScrollButton />
      </div>
    </>
  )
}

export default Footer
