import React from 'react'
import AnimatedCursor from '../common/AnimatedCursor'
import Footer from '../common/Footer'
import Header from '../common/header/Header'
import HomePage from '../page/home/HomePage'

function MainLayout() {
  return (
    <>
      <AnimatedCursor />
      <Header />
      <HomePage />
      <Footer />
    </>
  )
}

export default MainLayout
