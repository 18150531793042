import React from 'react'
import { Link } from 'react-router-dom'
function JoinUs(props) {
  return (
    <>
      <div
        className={`${'join-us-section wow animate fadeInDown'} ${
          props.padding
        }`}
        data-wow-duration="1.5s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="join-us-area">
            <div className="join-us-content flex-md-nowrap flex-wrap">
              <h2>Let&#x2019;s get in touch!</h2>
              <h5>Find out how we can help you expand your business</h5>
            </div>
            <div className="eg-btn join-btn hover-btn">
              <span>
                <Link
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                  to={`${process.env.PUBLIC_URL}/contact-us`}
                >
                  Contact us
                  <img
                    src={process.env.PUBLIC_URL + '/images/icon/arrow-dark.svg'}
                    alt="images"
                  />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JoinUs
